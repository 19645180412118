<template>
    <div style="background-color: #fff;position: relative">
        <div class="form-area pb-3">
            <el-form ref="form" :rules="rules"  :model="form" label-width="80px">
                <el-input type="hidden" v-model="form.id"></el-input>
                <el-input type="hidden" v-model="form.preview_url"></el-input>
                <el-input type="hidden" v-model="form.url"></el-input>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="width" label="宽度">
                            <el-input v-model="form.width"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="height" label="高度">
                            <el-input v-model="form.height"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="start_time" label="开始时间">
                            <el-date-picker
                                v-model="form.start_time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="end_time" label="结束时间">
                            <el-date-picker
                                v-model="form.end_time"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="times" label="展示次数">
                            <el-input-number size="small" v-model="form.times" :min="0" :max="10"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否启用">
                            <el-switch v-model="form.enable"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div style="margin-left: 80px;">
                    <el-upload
                        class="upload-demo"
                        drag
                        accept=".zip"
                        :show-file-list="false"
                        :http-request="upload"
                        action="http://api.bigemap.com/manage/api/upload/pcAd"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传zip文件</div>
                    </el-upload>
                </div>
                <div @click="preview" :style="{width:change.prevWidth+'px'}" v-if="form.preview_url" class="prev-box">
                    <div class="my-modal"></div>
                    <iframe v-if="form.preview_url" :src="form.preview_url" style="border: none;"
                            frameborder="0"></iframe>
                </div>
            </el-form>
            <div class="mt-3" style="margin-left: 80px;">
                <el-button @click="saveData" type="primary" plain>保存</el-button>
            </div>
            <div class="change-size" :class="{active:show}"  v-show="show" :style="{
                width:form.width+4+'px',
                height:form.height+49+'px',
                left:change.left+'px',
                top:change.top+'px'
            }">
                <div class="close">
                    <el-link @click="closeChange">x</el-link>
                </div>
                <div @mousedown="titleDown" class="title">
                   <span>设置显示大小</span>
                    <span>宽度:</span>
                    <span>
                        <el-input-number size="mini" v-model="form.width" :min="0" :max="140000"></el-input-number>
                    </span>
                    <span>高度:</span>
                    <span>
                        <el-input-number size="mini" v-model="form.height" :min="0" :max="80000"></el-input-number>
                    </span>
                </div>
                <div class="my-modal">

                </div>
                <div @mousedown.capture="bottomRightDown" class="resize">

                </div>

                <iframe @mouseup="mouseup" @mousemove="mousemove" :src="form.preview_url" style="border: none;width:100%;" :style="{height:form.height+'px'}"
                        frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "appPcAdAdd",
    components: {},
    created() {

    },
    mounted() {
        this.change.left=(window.innerWidth-this.form.width)/2;
        // console.log();
        // document.getElementsByClassName('.el-main')[0].getBoundingClientRect();
        document.addEventListener('mouseup',this.mouseup);
        document.addEventListener('mousemove',this.mousemove);
        let id = this.$route.params.id;
        if (id === 'add') return;
        this.$axios.get(this.baseUrl + '?id=' + id,).then(data => {
            this.form = data.data;
            this.form.enable=data.data.enable === 1;
        });
    },
    data: function () {
        return {
            show: false,
            baseUrl: '/manage/app/adPc',
            userGroup: [],
            tableData: [],
            rules:{
                title:[
                    { required: true, message: '请输入标题', trigger: 'blur' },
                ],
                times:[
                    { required: true, message: '请指定展示次数', trigger: 'blur' },
                ],
                width:[
                    { required: true, message: '请指定宽度', trigger: 'blur' },
                ],
                height:[
                    { required: true, message: '请指定高度', trigger: 'blur' },
                ],
                start_time:[
                    { required: true, message: '请指定开始日期', trigger: 'blur' },
                ],
                end_time:[
                    { required: true, message: '请指定结束日期', trigger: 'blur' },
                ],
            },
            form: {
                // preview_url: 'http://api.bigemap.com/uploads/20210811/7c8ab0175115fdf5a7bfb7737d7de841/index.html',
                preview_url: '',
                file: '',
                times: 0,
                enable:true,
                width: 800,
                height: 500
            },
            change: {
                prevWidth:200,
                timer:'',
                active: false,
                type: '',
                top:0,
                left:0
            }
        }
    },
    watch: {

    },
    methods: {
        saveData: function () {
            this.$refs['form'].validate((valid)=>{
                if (!valid) return false;
                if (!this.form.preview_url) {
                    this.$message.warning('请上传文件');
                    return  false;
                }
                if (this.form.start_time>this.form.end_time){
                    this.$message.warning('时间选择不合理');
                    return false;
                }
                let postData=JSON.parse(JSON.stringify(this.form));
                postData.enable=postData.enable?1:0;
                if (this.form.id) {
                    this.$axios.put(this.baseUrl, postData).then(() => {
                        this.$message.success('保存成功!');
                        this.$router.push('/app/pcad');
                    });
                } else {
                    this.$axios.post(this.baseUrl, postData).then(() => {
                        this.$message.success('添加成功');
                        this.$router.push('/app/pcad');
                    });
                }
            });
        },
        preview: function () {
            let bounds=document.getElementsByClassName('el-main')[0].getBoundingClientRect();
            this.change.left=(bounds.width-this.form.width)/2
            this.show = true;
            // console.log('abc');
        },
        upload: function (file) {
            let form = new FormData();
            form.append('file', file.file);
            this.$axios.post('/manage/api/upload/pcAd', form).then(data => {
                this.form.preview_url = data.data.preview;
                this.form.url = data.data.url;
            });
        },
        titleDown: function (e) {
            console.log(e);
            this.change.down={
                x:e.screenX,
                y:e.screenY,
                x1:this.change.left,
                y1:this.change.top,
            }
            this.change.active=true;
            this.change.type='move';
        },
        bottomRightDown: function (e) {
            this.change.active=true;
            this.change.down={
                x:e.screenX,
                y:e.screenY,
                w:this.form.width,
                h:this.form.height
            }
            this.change.type='resize';
        },
        mouseup: function () {
            this.change.active = false;
        },
        mousemove: function (e) {
            if (!this.change.active) return;
            switch (this.change.type) {
                case 'resize':
                    this.form.width = this.change.down.w + (e.screenX - this.change.down.x);
                    this.form.height = this.change.down.h + (e.screenY - this.change.down.y);
                    this.form.height<200?this.form.height=200:true;
                    this.form.height>800?this.form.height=800:true;
                    this.form.width<400?this.form.width=400:true;
                    this.form.width>1400?this.form.width=1400:true;
                    break;
                case 'move':
                    this.change.left=this.change.down.x1+(e.screenX-this.change.down.x);
                    this.change.top=this.change.down.y1+(e.screenY-this.change.down.y);
                    this.change.top<-75?this.change.top=-75:true;
                    break;
            }
        },
        closeChange:function (){
            this.show=false;
        }
    }
}
</script>

<style scoped lang="less">
.form-area {
    width: 800px;
    margin: 0 auto;
}
.prev-box {
    margin-top: 2rem;
    border: 2px dashed #ddd;
    height: 150px;
    margin-left: 80px;
    position: relative;
    .my-modal {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 9;
        right: 0;
        background-color:transparent;
    }
}

.change-size {
    position: absolute;
    background-color: #fff;
    z-index: 99;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 2px;
    box-shadow: 1px 1px 50px rgba(0,0,0,0.4);
    border: 2px dashed #ccc;
    .close{
        position: absolute;
        top:5px;
        right:5px;
    }
    .my-modal {
        position: absolute;
        left: 0;
        top: 50px;
        bottom: 0;
        cursor: pointer;
        //z-index: 9;
        right: 0;
        background-color:transparent;
    }
    .resize {
        position: absolute;
        right: 0;
        background: transparent;
        bottom: 0;
        width: 40px;
        cursor: se-resize;
        height: 40px;
        display: inline-block;
        user-select: none;
    }
    .title {
        span{
            padding: 0 10px;
        }
        border-bottom: 1px solid #f1f1f1;
        padding: 10px;
        cursor: move;
        user-select: none;
        border-radius: 5px;
    }
    &.active {

    }
}
</style>
